const newsEvents = {
    variable_1: '#1',
    init() {
        this.events();
    },
    events() {
        /**
         * Execute events
         */
        this.executeSlick();
        this.enableToggle();
    },    
    executeSlick() {
        jQuery('.news-events-slider').slick(
            {
                dots: true,
                infinite: true,
                speed: 500,
                fade: true,
                cssEase: 'linear',
                autoplay: false,
                arrows: false,
            }
        );
    },
    enableToggle() {
        jQuery('.news-events-switch span').on('click', function(){
            var toggle = jQuery(this).attr('data-toggle');
            jQuery('.news-events-switch span').removeClass('active');
            jQuery(this).addClass('active');
            jQuery('.news-events-slider').addClass('news-hide');
            jQuery('.news-events-slider[data-toggle='+toggle+']').removeClass('news-hide');
        });
    },
};
jQuery(() => {
    newsEvents.init();
});